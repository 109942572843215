<template>
  <div class="CreatorAccounts">
    <div class="edit-page-table-title">
      <b>{{ creator.first_name }} {{ creator.last_name }}</b> has the following
      accounts:
    </div>
    <DataTable
      :scrollable="true"
      :value="accounts"
      class="edit-page-table"
      scrollHeight="flex"
    >
      <Column
        sortable
        body-style="padding-left: 6%;"
        field="dashboard_id"
        header="Dashboard ID"
        header-style="background: var(--white) !important; padding-left: 3%;"
      >
      </Column>
      <Column
        sortable
        body-style="text-align: center;"
        header="Name"
        field="name"
        header-style="background: var(--white) !important; text-align: center;"
      >
      </Column>
      <Column
        sortable
        body-style="text-align: center;"
        field="type"
        header="Type"
        header-style="background: var(--white) !important; text-align: center;"
      >
      </Column>
      <Column
        sortable
        body-style="text-align: center;"
        field="contract_status"
        header="Contract Status"
        header-style="background: var(--white) !important; text-align: center;"
      >
      </Column>
      <Column
        sortable
        body-style="text-align: center;"
        field="tier"
        header="Tier"
        header-style="background: var(--white) !important; text-align: center;"
      >
      </Column>
      <Column
        sortable
        body-style="text-align: center;"
        field="expiration_date"
        header="Expiration Date"
        header-style="background: var(--white) !important; text-align: center;"
      >
      </Column>

      <Column
        body-style="text-align: center; color: var(--primary-1);"
        header-style="background: var(--white) !important;"
      >
        <template #body="slotProps">
          <router-link :to="'/mastersheet/' + slotProps.data.id">
            <i class="pi pi-external-link" />
          </router-link>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { readAccounts, readCreator } from "@/store/creator/getters";

@Component
export default class CreatorAccounts extends Vue {
  get creator() {
    return readCreator(this.$store);
  }

  get accounts() {
    return readAccounts(this.$store);
  }
}
</script>

<style scoped>
.CreatorAccounts {
  padding-left: 2.8%;
  padding-right: 2.4%;
  height: 100%;
  right: 0;
  top: 95px;
}
</style>
