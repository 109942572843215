<template>
  <div class="p-d-flex Creator">
    <CreatorEdit ref="creatorEdit"></CreatorEdit>
    <div id="right-edit-block">
      <b-tabs
        active-nav-item-class="manage-edit-tab-active"
        align="right"
        class="manage-edit-tabs"
        content-class="mt-3"
      >
        <b-tab active title="Comments Log" title-link-class="manage-edit-tab">
          <CreatorComments />
        </b-tab>
        <b-tab title="Managers" title-link-class="manage-edit-tab">
          <CreatorManagers></CreatorManagers>
        </b-tab>
        <b-tab
          title="Accounts"
          title-link-class="manage-edit-tab right-most-tab"
        >
          <CreatorAccounts></CreatorAccounts>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { readCreator } from "@/store/creator/getters";
import CreatorEdit from "@/views/manage/creator/Creator/CreatorEdit.vue";
import CreatorAccounts from "@/views/manage/creator/Creator/CreatorEdit/CreatorAccounts.vue";
import CreatorComments from "@/views/manage/creator/Creator/CreatorEdit/CreatorComments.vue";
import CreatorManagers from "@/views/manage/creator/Creator/CreatorEdit/CreatorManagers.vue";
import {
  dispatchGetCreator,
  dispatchListAccounts,
} from "@/store/creator/actions";
import {
  dispatchListCrews,
  dispatchListManagerRoles,
} from "@/store/main/actions";

@Component({
  components: {
    CreatorEdit,
    CreatorAccounts,
    CreatorComments,
    CreatorManagers,
  },
})
export default class Creator extends Vue {
  get creator() {
    return readCreator(this.$store);
  }

  public async mounted() {
    const creatorId = parseInt(this.$route.params.id, 0);
    window["analytics"]["page"](`/manage/creators/${creatorId}`);
    await dispatchGetCreator(this.$store, { id: creatorId });
    this.$refs.creatorEdit["reset"]();
    await dispatchListCrews(this.$store);
    await dispatchListManagerRoles(this.$store);
    await dispatchListAccounts(this.$store, { id: creatorId });
  }

  beforeRouteLeave(to, from, next) {
    if (this.$refs.creatorEdit["edited"]()) {
      if (!window.confirm("Leave without saving?")) {
        return;
      }
    }
    next();
  }

  public beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  }

  preventNav(event) {
    if (!this.$refs.creatorEdit["edited"]()) {
      return;
    }
    event.preventDefault();
    event.returnValue = "";
  }
}
</script>

<style>
.Creator {
  background-color: var(--white);
  height: 100%;
  overflow: hidden;
  position: relative;
}

#right-edit-block {
  height: 100%;
  width: 50%;
}
</style>
