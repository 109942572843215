<template>
  <div class="CreatorManagers">
    <div class="edit-page-table-title">
      <b>{{ creator.first_name }} {{ creator.last_name }}</b> is managed by:
    </div>
    <DataTable
      :scrollable="true"
      :value="managers"
      class="edit-page-table"
      scrollHeight="flex"
    >
      <Column
        body-style="width: 5rem; padding-left: 2rem"
        header-style="background: var(--white) !important; text-align: center; width: 5rem;"
      >
        <template #body="slotProps">
          <avatar
            :username="slotProps.data.first_name"
            :src="slotProps.data.photo_url"
            alt="avatar"
          />
        </template>
      </Column>
      <Column
        body-style="padding-left: 6%;"
        header="Manager"
        header-style="background: var(--white) !important; padding-left: 6%;"
      >
        <template #body="slotProps">
          <router-link
            :to="`/manage/managers/${slotProps.data.manager_id}`"
            style="cursor: pointer; text-decoration: none; color: #495057"
          >
            {{ slotProps.data.first_name }} {{ slotProps.data.last_name }}
          </router-link>
        </template>
      </Column>
      <Column
        body-style="text-align: center;"
        header="Email"
        header-style="background: var(--white) !important; text-align: center;"
      >
        <template #body="slotProps">
          {{ slotProps.data.email }}
        </template>
      </Column>
      <Column
        body-style="text-align: center;"
        header="Team"
        header-style="background: var(--white) !important; text-align: center;"
      >
        <template #body="slotProps">
          {{ getTeamName(slotProps.data.team_id) }}
        </template>
      </Column>
      <Column
        body-style="text-align: center;"
        header="Office"
        header-style="background: var(--white) !important; text-align: center;"
      >
        <template #body="slotProps">
          {{ getOfficeName(slotProps.data.office_id) }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { readCreator } from "@/store/creator/getters";
import { readTeams, readOffices } from "@/store/main/getters";
import { dispatchListOffices, dispatchListTeams } from "@/store/main/actions";
import MappingGroups from "@/views/manage/mapping-group/MappingGroups.vue";
import { api } from "@/api";
import { readIsManager, readIsSuper } from "@/store/auth/getters";

@Component({
  components: {
    MappingGroups,
  },
})
export default class CreatorManagers extends Vue {
  public managers = [];

  get creator() {
    return readCreator(this.$store);
  }

  get teams() {
    return readTeams(this.$store);
  }

  get offices() {
    return readOffices(this.$store);
  }

  public getTeamName(teamId: number) {
    const filtered = this.teams.filter((item) => item.id === teamId);
    if (filtered.length) {
      return filtered[0].name;
    }
    return "N/A";
  }

  public getOfficeName(officeId: number) {
    const filtered = this.offices.filter((item) => item.id === officeId);
    if (filtered.length) {
      return filtered[0].name;
    }
    return "N/A";
  }

  public async mounted() {
    if (!this.teams.length) {
      await dispatchListTeams(this.$store);
    }
    if (!this.offices.length) {
      await dispatchListOffices(this.$store);
    }
    const creatorId = parseInt(this.$route.params.id, 0);
    if (creatorId) {
      const response = await api.listManagersByCreator(creatorId);
      if (response) {
        this.managers = Array.from(
          new Set(
            response.data.map((manager) => {
              return {
                first_name: manager.first_name,
                last_name: manager.last_name,
                email: manager.email,
                team_id: manager.team_id,
                office_id: manager.office_id,
                photo_url: manager.photo_url,
              };
            })
          )
        );
      }
    }
  }

  get isSuper() {
    return readIsSuper(this.$store);
  }

  get isManager() {
    return readIsManager(this.$store);
  }

  get readonly() {
    return !this.isManager;
  }
}
</script>

<style scoped>
.CreatorManagers {
  padding-left: 2.8%;
  padding-right: 2.4%;
  height: 100%;
  right: 0;
  top: 95px;
}
</style>
